import { Box, LinearProgress } from "@mui/material";

import { FuzzySearchLocation, FuzzySearchTopic } from "./utils/types";

import { featureFlags } from "common/util/envHelpers";

import Row from "common/components/Row";
import { FuzzySearchModalFooter } from "./FuzzySearchModalFooter";
import { FuzzySearchModalList } from "./FuzzySearchModalList";

export interface FuzzySearchModalProps {
  searching?: boolean;
  locations: FuzzySearchLocation[];
  topics: FuzzySearchTopic[];
  searchTerm: string;
  width?: string | number;
  maxWidth?: string | number;
  showRecentSearchesInFooter?: boolean;
  uiLocation: string;
}

export const FuzzySearchModal: React.FC<FuzzySearchModalProps> = ({
  searching,
  locations,
  topics,
  searchTerm,
  width,
  showRecentSearchesInFooter,
  uiLocation
}) => {
  return (
    <Box
      tabIndex={0}
      role="button"
      sx={({ zIndex }) => ({
        position: "absolute",
        top: "100%",
        right: 0,
        bgcolor: "common.white",
        border: "1px solid transparent",
        boxShadow: "0 15px 15px 0 rgba(0,0,0,.1)",
        display: "flex",
        flexDirection: "column",
        maxHeight: "500px",
        width,
        maxWidth: width ? undefined : "800px",
        zIndex: zIndex.modal
      })}
    >
      {searching && (
        <Box sx={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Row gap={3} sx={{ p: 3, width: "100%", minHeight: { sm: "none", md: 250 } }}>
        <FuzzySearchModalList
          type="Locations"
          searchTerm={searchTerm}
          items={locations}
          uiLocation={uiLocation}
          searching={searching}
        />
        <FuzzySearchModalList
          type="Topics"
          searchTerm={searchTerm}
          items={topics}
          uiLocation={uiLocation}
          searching={searching}
        />
      </Row>
      {featureFlags.showSearchBarFooter && (
        <FuzzySearchModalFooter
          showRecentSearches={showRecentSearchesInFooter}
          uiLocation={uiLocation}
        />
      )}
    </Box>
  );
};
