"use client";

import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, ClickAwayListener, Stack, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import isNil from "lodash/isNil";

import { FuzzySearchLocation, FuzzySearchTopic } from "./utils/types";

import { theme } from "theme/theme";
import { locationPathRecreation } from "./utils/locationPathRecreation";
import { topicPathRecreation } from "./utils/topicPathRecreation";
import { logError } from "common/util/consoleHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";
import { fuzzySearch } from "layout/SiteTopNav/FuzzySearch/utils/search";

import RotatingPlaceholder from "layout/SiteTopNav/FuzzySearch/RotatingPlaceholder";
import { CloseButton } from "component/button";
import { FuzzySearchModal, FuzzySearchModalProps } from "./FuzzySearchModal";
import FuzzySearchRecents from "./FuzzySearchRecents";

type FuzzySearchProps = {
  minHeight?: number | string;
  modalProps?: Pick<FuzzySearchModalProps, "maxWidth" | "width">;
  showRecentSearchesUnderInput?: boolean;
  uiLocation: string;
};

export const FuzzySearch: React.FC<FuzzySearchProps> = ({
  modalProps = {},
  showRecentSearchesUnderInput = false,
  uiLocation
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [dynamicPlaceholderEnabled, setDynamicPlaceholderEnabled] = useState<boolean>(true);
  const [focused, setFocused] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [locations, setLocations] = useState<FuzzySearchLocation[]>([]);
  const [topics, setTopics] = useState<FuzzySearchTopic[]>([]);
  const { pathWithParamKeys, params } = useRouterPath();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!open) {
      setDynamicPlaceholderEnabled(true);
    }
  }, [open]);

  const onFocus = useCallback(() => {
    setFocused(true);
    setOpen(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleClose = useCallback(
    (clearSearch: boolean) => {
      inputRef.current?.blur();
      setFocused(false);
      setOpen(false);
      setDynamicPlaceholderEnabled(true);
      setLocations([]);
      setTopics([]);
      if (clearSearch && inputRef.current !== null) {
        setSearchTerm("");
        inputRef.current.value = "";
        inputRef.current.focus();
      }
      sendGaUserInteractionEvent({
        category: "Fuzzy Search",
        action: "Toggle (close)",
        ui_location: uiLocation
      });
    },
    [uiLocation]
  );

  const onKeyDown = useCallback(() => {
    setDynamicPlaceholderEnabled(false);
  }, []);

  const getSearchResults = useCallback(
    (query = "") => {
      const searchTerm = query.trim();
      if (searchTerm.length <= 2) {
        setSearchTerm("");
        setLocations([]);
        setTopics([]);
        setSearching(false);
        return;
      }
      setSearchTerm(query?.trim());
      setSearching(true);
      fuzzySearch(query, uiLocation)
        .then(({ locations, topics }) => {
          // Update locations to consume the actual location
          const processedLocations: FuzzySearchLocation[] = locationPathRecreation({
            locations,
            pathname: pathWithParamKeys,
            params
          });
          const processedTopics: FuzzySearchTopic[] = topicPathRecreation(topics, params);
          setLocations(processedLocations);
          setTopics(processedTopics);
        })
        .catch((err) => logError(err, "Error performing Fuzzy Search"))
        .finally(() => {
          setSearching(false);
          sendGaUserInteractionEvent({
            category: "Fuzzy Search",
            action: "Type search item",
            label: searchTerm,
            ui_location: uiLocation
          });
        });
    },
    [params, pathWithParamKeys, uiLocation]
  );

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e) => {
      const value = (e.target.value ?? "").trim().toLowerCase();
      if (!value) setDynamicPlaceholderEnabled(true);
      getSearchResults(value);
    },
    [getSearchResults]
  );

  const debouncedOnChange = debounce(onChange, 500);

  useEffect(() => {
    if (open) {
      sendGaUserInteractionEvent({
        category: "Fuzzy Search",
        action: "Toggle (open)",
        ui_location: uiLocation
      });
    }
  }, [open, uiLocation]);

  useEffect(() => {
    const handleClearSearchWithKeyboard = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        handleClose(false);
        if (!isNil(inputRef) && !isNil(inputRef.current)) {
          inputRef.current.value = "";
        }
      }
    };
    document.addEventListener("keydown", handleClearSearchWithKeyboard, false);
    return () => document.removeEventListener("keydown", handleClearSearchWithKeyboard, false);
  }, [handleClose]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => handleClose(false)}
        mouseEvent={open ? "onMouseUp" : false}
      >
        <Box
          id="wrapper"
          sx={{
            position: "relative",
            display: "flex",
            width: "100%",
            flexDirection: "column"
          }}
        >
          <Box sx={{ position: "relative", height: "100%" }}>
            <TextField
              fullWidth
              size="small"
              inputRef={inputRef}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={debouncedOnChange}
              InputProps={{
                "aria-label": "search",
                startAdornment: (
                  <SearchIcon sx={{ color: theme.palette.primary.main, fontWeight: 800 }} />
                ),
                endAdornment: searchTerm.length > 0 && (
                  <CloseButton
                    aria-label="Clear search"
                    sx={{
                      fontWeight: 800,
                      color: theme.palette.primary.main,
                      "&:hover": {
                        color: theme.palette.primary.main
                      }
                    }}
                    onClick={() => handleClose(true)}
                  />
                )
              }}
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: "inherit",
                "& .MuiInputBase-input": {
                  px: 1,
                  transition: theme.transitions.create("width")
                }
              }}
            />
            {!searchTerm && dynamicPlaceholderEnabled && (
              <Stack
                sx={{
                  overflow: "hidden",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "calc(100% - 3px)",
                  borderRadus: theme.shape.borderRadius,
                  pointerEvents: "none"
                }}
              >
                <RotatingPlaceholder
                  rotationTimeInterval={2000}
                  rotationEnabled={focused}
                  placeholders={[
                    "Search for your community or a topic",
                    "Enter your county, ZIP code, census tract, etc",
                    "Search for topics like Asthma or Drug Overdose Deaths"
                  ]}
                />
              </Stack>
            )}
          </Box>
          {open && (
            <FuzzySearchModal
              searching={searching}
              locations={locations}
              topics={topics}
              searchTerm={searchTerm}
              showRecentSearchesInFooter={!showRecentSearchesUnderInput}
              uiLocation={uiLocation}
              {...modalProps}
            />
          )}
        </Box>
      </ClickAwayListener>
      {open && showRecentSearchesUnderInput && <FuzzySearchRecents uiLocation={uiLocation} />}
    </>
  );
};
