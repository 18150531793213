import compact from "lodash/compact";

const AGGREGATE_PARAMS = ["category", "action", "label", "value", "ui_location"];

export const GA_EVENT_TRACKING_ENABLED = !!process.env.NEXT_PUBLIC_GA4_TRACKING_ID;

export const GaEventTypes = {
  Click: "click", // clicks of *outbound* links - https://support.google.com/analytics/answer/9234069?sjid=10768985373875242647-NA#click
  UserInteraction: "user_interaction", // Non-navigational interactions
  FileDownload: "file_download", //  https://support.google.com/analytics/answer/9234069?sjid=10768985373875242647-NA#file_download
  Navigation: "navigation", // custom
  Search: "search" // custom
} as const;
const gaEventTypesValues = Object.values(GaEventTypes);
export type GaEventType = (typeof gaEventTypesValues)[number];

// The `categories` are broad groups of events.
// It is important that events in the same component/area
// have the same category in order to facilitate grouping events in GA.
export const GaEventCategories = {
  Accordions: "Accordions",
  Charts: "Charts",
  ContextSwitcher: "Context Switcher",
  DataDownload: "Data Download",
  Embed: "Embed",
  FuzzySearch: "Fuzzy Search",
  Headers: "Headers",
  Indicator: "Indicator",
  KPI: "KPI",
  LocationPicker: "Location Picker",
  LocationSwitcher: "Location Switcher",
  Maps: "Maps",
  Modals: "Modals",
  PageTabs: "Page Tabs",
  PdfDownload: "PDF Download",
  Popovers: "Popovers",
  SideNav: "Side Nav",
  Tables: "Tables",
  Tooltips: "Tooltips"
} as const;
const gaEventCategoryValues = Object.values(GaEventCategories);
export type GaEventCategoryType = (typeof gaEventCategoryValues)[number];

// Common actions
export const GaEventActions = {
  BreadcrumbClick: "Breadcrumb click",
  LocationClick: "Location click",
  LocationPickerClick: "Location picker click",
  ToggleClick: "Toggle click"
} as const;

// These should map to any custom dimensions we have set up in GA
// Or any other default dimensions we want to send
export interface GaClickEventParameters {
  category: GaEventCategoryType;
  action: string;
  label?: string;
  ui_location?: string;
}

export const gaMapActions = {
  polygonClick: "Location polygon click",
  polygonMouseOver: "Location polygon mouseover"
};

export const gaTableActions = {
  rowClick: "Row click"
};

export const gaActions: Record<string, Record<string, string>> = {
  [GaEventCategories.Maps]: gaMapActions
};

interface SendGaEventParams {
  eventType: GaEventType;
  parameters: GaClickEventParameters;
}

export const sendGaEvent = ({ eventType, parameters }: SendGaEventParams) => {
  if (!GA_EVENT_TRACKING_ENABLED) return;
  if (typeof gtag === "undefined" || gtag === undefined || !gtag || !parameters) return;
  const aggregate = compact(
    AGGREGATE_PARAMS.map((p) => parameters[p as keyof GaClickEventParameters])
  ).join(" | ");
  const _params = {
    ...parameters,
    aggregate
  };
  gtag("event", eventType, _params);
};

export const sendGaSearchEvent = (parameters: GaClickEventParameters) =>
  sendGaEvent({ eventType: GaEventTypes.Search, parameters });

export const sendGaUserInteractionEvent = (parameters: GaClickEventParameters) =>
  sendGaEvent({ eventType: GaEventTypes.UserInteraction, parameters });

export const sendGaNavigationEvent = (parameters: GaClickEventParameters) =>
  sendGaEvent({ eventType: GaEventTypes.Navigation, parameters });
